document.addEventListener('DOMContentLoaded', function () {
  const hiddenInstructions  = document.querySelectorAll('.instruction:not(.instruction--main)'),
      showInstructionBtns = document.querySelectorAll('.show-instruction'),
      returnBtns          = document.querySelectorAll('.btn--return'),
      copyBtns            = document.querySelectorAll('.btn--copy');

  hiddenInstructions.forEach(hiddenInstruction => hiddenInstruction.classList.add('instruction--hidden'));

  showInstructionBtns.forEach(instructionBtn => {
    instructionBtn.addEventListener('click', function () {
      const showInstruction = this.dataset.showInstruction,
            pitName         = this.dataset.pitName;

      this.closest('.instruction').classList.add('instruction--hidden');
      document.querySelector('.' + showInstruction).classList.remove('instruction--hidden');

      if (pitName !== undefined) {
        document.querySelector('.pit-name').textContent = pitName;
        document.querySelector('.btn--download').setAttribute('data-url', "https://www.fundacjahospicyjna.pl/images/1procent/1.5procent_2023/PIT_" + pitName + "_2024.pdf");
      }
    });
  });

  returnBtns.forEach(returnBtn => {
    returnBtn.addEventListener('click', function () {
      const returnSection = this.dataset.returnSection;
      this.closest('.instruction').classList.add('instruction--hidden');
      document.querySelector('.' + returnSection).classList.remove('instruction--hidden');
    });
  });

  copyBtns.forEach(copyBtn => {
    copyBtn.addEventListener('click', function () {
      const copyText = this.dataset.textCopy;

      navigator.clipboard.writeText(copyText).then(function () {
        const originalText = copyBtn.textContent;
        copyBtn.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="#f3a102"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>';
        copyBtn.setAttribute("disabled", "disabled");

        setTimeout(() => {
          copyBtn.innerHTML = originalText;
          copyBtn.removeAttribute("disabled");
        }, 1500);
      });
    });
  });
});
