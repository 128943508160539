document.addEventListener('DOMContentLoaded', function () {
    const items =  document.querySelectorAll('.accordion__item'),
        questions = document.querySelectorAll('.accordion__header');

    items.forEach((item) => item.classList.remove("accordion__item--active"));


    questions.forEach(question => {
        question.addEventListener('click', function () {
            if (!this.parentNode.classList.contains('accordion__item--active')) {
                items.forEach(item => item.classList.remove('accordion__item--active'));
            }
            this.parentNode.classList.toggle('accordion__item--active');
       });
    });
});
