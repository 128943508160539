document.addEventListener("DOMContentLoaded", function () {
  const suportContainer = document.querySelector(".js-support-sections");

  const flipCard = () => {
    const infoCards = document.querySelectorAll(".card--info"),
      buttons = document.querySelectorAll(".card--info .btn");

    buttons.forEach((button) => {
      button.addEventListener("click", () => {
        const card = button.closest(".card");
        if (!card.classList.contains("card--flipped")) {
          infoCards.forEach((card) => card.classList.remove("card--flipped"));
        }
        card.classList.toggle("card--flipped");
      });
    });
  };

  const hideDescription = (cards, descriptionCards, buttons) => {
    cards.forEach((card) => card.classList.remove("card--active"));
    descriptionCards.forEach((card) => card.parentNode.remove());
    buttons.forEach((button) => {
      button.classList.remove("showless");
      button.classList.add("showmore");
      button.textContent = button.dataset.more;
    });
  };

  const checkForWindowResize = (mediaQuery, cards, buttons) => {
    mediaQuery.addEventListener("change", () => {
      const descriptionCards = document.querySelectorAll(".card--description");
      hideDescription(cards, descriptionCards, buttons);
    });
  };

  const showDescription = () => {
    const cards = document.querySelectorAll(".card--institution"),
      buttons = document.querySelectorAll(".card--institution .btn"),
      mediaQuery = window.matchMedia("(max-width: 767px)");

    buttons.forEach((button) => {
      button.addEventListener("click", () => {
        const card = button.closest(".card"),
          descriptionCards = document.querySelectorAll(".card--description"),
          newDescriptionCard = document.createElement("div");

        newDescriptionCard.classList.add("col-xs-12", "card-container");
        newDescriptionCard.innerHTML = `<div class='card card--sm card--description'><div class='card__body'>${card.dataset.description}</div></div>`;

        if (!card.classList.contains("card--active")) {
          hideDescription(cards, descriptionCards, buttons);
        }
        card.classList.toggle("card--active");
        button.classList.toggle("showmore");
        button.classList.toggle("showless");

        button.classList.contains("showless")
          ? (button.textContent = button.dataset.less)
          : (button.textContent = button.dataset.more);

        if (mediaQuery.matches) {
          button.classList.contains("showless")
            ? card.parentNode.after(newDescriptionCard)
            : descriptionCards.forEach((card) => card.parentNode.remove());
        } else {
          button.classList.contains("showless")
            ? cards[cards.length - 1].parentNode.after(newDescriptionCard)
            : descriptionCards.forEach((card) => card.parentNode.remove());
        }
      });
    });

    checkForWindowResize(mediaQuery, cards, buttons);
  };

  window.addEventListener("scroll", function cb(event) {
    const url = suportContainer.getAttribute("data-url").replace("#", "");

    if (suportContainer.innerHTML.trim() === "") {
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          suportContainer.innerHTML = data.html;
          flipCard();
          showDescription();
        });
    }

    event.currentTarget.removeEventListener(event.type, cb);
  });
});
