document.addEventListener('DOMContentLoaded', function () {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  if (isMobile) {
    document.querySelector('body').classList.add('mobile');

  } else {
    const slider       = document.querySelector('.slider'),
          sliderDots   = document.querySelectorAll('.slider__dot'),
          sliderBtns   = document.querySelectorAll('.slider__btn'),
          prevBtn      = document.querySelector('.slider__btn--prev'),
          nextBtn      = document.querySelector('.slider__btn--next'),
          returnBtn    = document.querySelector('.instruction--types .btn--return'),
          sliderSlides = document.querySelectorAll('.slider__slide');
    let activeIndex = 0;

    slider.classList.add('slider--active');

    for (let i=1; i<sliderSlides.length; i++) {
      sliderSlides[i].classList.add('slider__slide--hidden');
    }

    function showSlides () {
      sliderBtns.forEach(sliderBtn => sliderBtn.classList.remove('slider__btn--hidden'));
      sliderDots.forEach(sliderDot => sliderDot.classList.remove('slider__dot--active'));
      sliderDots[activeIndex].classList.add('slider__dot--active');

      sliderSlides.forEach(sliderSlide => sliderSlide.classList.add('slider__slide--hidden'));
      sliderSlides[activeIndex].classList.remove('slider__slide--hidden');

      if (activeIndex === 0) {
        prevBtn.classList.toggle('slider__btn--hidden');
      } else if (activeIndex === sliderSlides.length - 1) {
        nextBtn.classList.toggle('slider__btn--hidden');
      } else {
        sliderBtns.forEach(sliderBtn => sliderBtn.classList.remove('slider__btn--hidden'));
      }
    }

    sliderDots.forEach((sliderDot, index) => {
      sliderDot.addEventListener('click', function () {
        activeIndex = index;
        showSlides();
      });
    });

    sliderBtns.forEach(sliderBtn => {
      sliderBtn.addEventListener('click', function () {
        this.classList.contains('slider__btn--next') ? activeIndex++ : activeIndex--;
        showSlides();
      });
    });

    returnBtn.addEventListener('click', function () {
      activeIndex = 0;
      showSlides();
    });
  }
});
