document.addEventListener('DOMContentLoaded', function () {
  const form = document.getElementById('new_contact_form');

  function replaceForm(html) {
    let parser = new DOMParser(),
      doc = parser.parseFromString(html,"text/html"),
      newForm = doc.getElementById('new_contact_form');

    form.innerHTML = newForm.innerHTML;
  }

  form.addEventListener("ajax:success", function(event) {
    let html = event.detail[0].html;

    replaceForm(html);
    const alertContainer = document.querySelector('.alert-container');
    alertContainer.innerHTML = '<div class="alert alert--success">Wiadomość wysłana pomyślnie!</div>';
  });

  form.addEventListener("ajax:error", function(event) {
    let html = event.detail[0].html;

    replaceForm(html);
    const alertContainer = document.querySelector('.alert-container');
    alertContainer.innerHTML = '<div class="alert alert--danger">W formularzu wystąpiły błędy.</div>';
  });
});
