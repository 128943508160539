import "custom/instruction";
import "custom/slider";
import "custom/contact_form";
import "custom/faq";
import "custom/support";

import Rails from "@rails/ujs";
Rails.start();

import "src/js_link";
