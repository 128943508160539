document.addEventListener('DOMContentLoaded', function () {
  const jsLinks = document.body.querySelectorAll('.js-link:not(.login-required)');

  jsLinks.forEach(jsLink => {
    jsLink.addEventListener('mousedown', event => {
      replaceDataHrefWithHref(event);
    });

    jsLink.addEventListener('click', event => {
      replaceDataHrefWithHref(event);
    });
  });

  function replaceDataHrefWithHref(event) {
     let dataUrl = event.currentTarget.getAttribute('data-url'),
      subdomain = event.currentTarget.getAttribute('data-subdomain'),
      url;
    dataUrl = dataUrl.replace(/^#/, '');
    url = dataUrl;

    if (subdomain != undefined && subdomain !== '' && !dataUrl.match(/^http/)) {
      let host = window.location.host.split('.');
      host[0] = subdomain;
      url = (window.location.protocol === 'https:' ? 'https://' : 'http://') + host.join('.') + dataUrl;
    }

    event.currentTarget.setAttribute('href', url);
    event.currentTarget.classList.remove('js-link');
  }
});
